@import '@/components/mixins.scss';

.container {
  padding: rem(50) rem(30);
}

label {
	display: inline-block;
	margin-bottom: 0 !important;
}

:global(.ant-form-item) {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000 !important;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  margin-bottom: 0 !important;
  vertical-align: top;
}